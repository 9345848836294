export enum CustomerInfoDataTestIds {
    customerInfoHeading = 'customerinfoCustomerInfoHeading',
    deliveryPageHeading = 'deliveryDeliveryHeading',
    paymentPageHeading = 'paymentPageTitle',
    FirstName = 'customerinfoShippingAddressFirstName',
    LastName = 'customerinfoShippingAddressLastName',
    Email = 'customerinfoShippingAddressEmail',
    Phone = 'customerinfoShippingAddressPhone',
    EmailMeNewsCheckBox = 'customerinfoEmailM',
    BillingAddressSameCheckBox = 'paymentCheckoutBillingAddressCheckBox',
    Address = 'customerinfoShippingAddressAddress',
    Apt = 'customerinfoShippingAddressApt',
    ZipCode = 'customerinfoShippingAddressZipCode',
    City = 'customerinfoShippingAddressCity',
    State = 'customerinfoShippingAddressState',
    ExpressCustomerInfo = 'customerinfoHeading'
}

export enum CheckoutStepperDataTestIds {
    customerInfoStepper = 'customerinfoStepperModuleWrapper',
    deliveryInfoStepper = 'deliveryStepperModuleWrapper',
    paymentInfoStepper = 'paymentstepperModuleWrapper'
}

export const SynchronyFinnacinTestIds = [
    { learnhow: 'paymentSynchrony6monthLearnHowLink', radio: 'paymentSynchrony6monthRadioSelect' },
    { learnhow: 'paymentSynchrony12monthLearnHowLink', radio: 'paymentSynchrony12monthRadioSelect' },
    { learnhow: 'paymentSynchrony24monthLearnHowLink', radio: 'paymentSynchrony24monthRadioSelect' }
];

export enum PaymentSteppDataTestIds {
    CreditCardRadioSelect = "paymentCreditCardRadioSelectButton",
    Creditcardicons = "paymentCreditCardIconsImage",
    CheckoutBillingaddressCheckBox = "paymentCheckoutBillingAddressCheckBox",
    PayPalRadioSelect = "paymentPayPalRadioSelectButton",
    PayPalInstrumentalInfo = "paymentPayPalInfo",
    SynchronyRadioSelect = "paymentSynchronyRadioSelectButton",
    SynchronyImage = "paymentSynchronyIconImage",
    SynchronyPrequalify = "paymentSynchronyPrequalifyButton",
    Synchronyuseexistingaccount = "paymentSynchronyUseExistingAccountButton",
    Checkoutguidedcardcontent = "paymentCheckoutGuidedCardContent",
    Checkouteditcustomerinfo = "paymentCheckoutEditCustomerInfoLink",
    Deliverysummarycontent = "paymentDeliverySummaryContent",
    Deliverycontentedit = "paymentDeliveryContentIdLink",
    Submitorder = "paymentSubmitOrderButton"
}

export enum CheckoutButtonDataTestIds {
    SubmitOrderId = 'paymentSubmitOrderButton',
    ContinueToDeliveryId = 'customerinfoContinueDeliveryButton',
    ContinueToPaymentId = 'deliveryContinueToPaymentButton'
}

export enum DeliveryDataTestIds {
    DeliveryOptionSchedule = "deliveryDeliveryOptionScheduleTitle",
    DeliveryOptionsLinkWrapper = "deliveryDeliveryOptionLinkWrapper",
    DeliveryOptionScheduleDateButton = "deliveryDeliveryOptionScheduleDateButton",
    DeliveryTimeSelect = "deliveryDeliveryTimeSelectOption",
    DeliveryOptionHeading = "deliveryDeliveryOptionTitle",
    DeliveryOptionServiceWrapper = "deliveryDeliveryOptionServiceWrapper"
}

export enum OrderSummaryCheckoutDataTestIds {
    OrderSummaryHeading = "OrderSummaryHeading",
    ItemsSubtotallabel = "OrderSummaryLineSubTotalLabel",
    ItemsSubtotal = "OrderSummaryLineSubTotal",
    SavingsLabel = "OrderSummarySavingsLabel",
    Savings = "OrderSummarySavings",
    TaxLabel = "OrderSummaryTaxLabel",
    Tax = "OrderSummaryTax",
    TotalLabel = "OrderSummaryLineTotaLabel",
    Total = "OrderSummaryLineTotal"
}

export enum CartDataTestIds {
    PencilBanner = "promoBanner",
    StepperModuleWrapper = "cartpageStepperModuleWrapper",
    ShoppingCartHeading = "cartpageShoppingCartHeading",
    ZipCodeLink = "cartpageDeliveryMessageLink",
    QtyDropDown = "cartpageQtySelectMenu",
    DeleteItemLink = "cartpageRemoveItemButton",
    AddPromoCode = "cartpageAddPromoCodeLink",
    CheckoutButton = "cartpageCheckoutButton",
    PayPalButton = "cartpagePaypalLogoButton",
    CreditCardLearnHowLink = "cartpageMattressfirmCreditCardLearnHowLink",
    LookForLeaseLearnHowLink = "cartpageLookForLeasetoOptionLearnHowLink",
    SleepExpertLinks = "cartpageTalkToSleepExpert{buttonType}Link",
}

export enum PaypalExpressDataTestIds {
    PaypalCustomerInfoHeading = 'PayPalExpresscustomerInfoHeading',
    PaypalStepperModule = 'PayPalExpressStepperModuleWrapper',
    PaypalFirstName = 'PayPalExpressshippingAddressfirstname',
    PaypalLastName = 'PayPalExpressshippingAddresslastname',
    PaypalEmail = 'PayPalExpressshippingAddressEmail',
    PaypalPhone = 'PayPalExpressshippingAddressPhone',
    PaypalAddress = 'PayPalExpressshippingAddressAddress',
    PaypalApt = 'customerinfoShippingAddressApt',
    PaypalZipCode = 'PayPalExpressshippingAddressZipcode',
    PaypalCity = 'PayPalExpressshippingAddressCity',
    SaveandContinueBtn = 'PayPalExpressSaveandContinueButton',
    ExpressDeliveryHeading = 'PayPalExpressdeliveryHeading',
    ExpressDeliveryContent = 'PayPalExpressdeliveryContent',
    ExpressDeliveryEditLink = 'PayPalExpressdeliveryEditLink',
    ExpressPaymentHeading = 'PayPalExpresspaymentMethodHeading',
    ExpressReviewEmail = 'PayPalExpressreviewEmail',
    ExpressPaymentEdit = 'PayPalExpresspaymentEditEditLink',
    ExpressOrderSummaryHeading = 'PayPalExpressorderSummaryHeading',
    ExpressItemsSubtotal = 'PayPalExpressorderSummarylineSubtotal',
    ExpressSavings = 'PayPalExpressorderSummarySavings',
    ExpressTax = 'PayPalExpressorderSummaryTax',
    ExpressTotal = 'PayPalExpressorderSummarylineTotal',
    ExpressorderReviewHeading = 'PayPalExpressorderReviewHeading',
    ExpressorderReviewContainer = 'PayPalExpressorderReviewPaypalExpressContainer',
    ExpresssubmitOrderBtn = 'PayPalExpresssubmitOrderButton'
}

//data testid's that will be used on OC page
export enum OrderConfirmationDataTestIds {
    ThanksTitle = "confirmationThankYouTitle",
    OrderNumber = "confirmationOrderNumber",
    OrderPlacedLabel = "confirmationOrderPlacedLabel",
    OrderPlacedDetails = "confirmationOrderPlacedDetails",
    OrderNumberDetails = "confirmationOrderNumberDetails",
    CustomerInfo = "confirmationCustomerInfoSection",
    DeliverySection = "confirmationDeliverySection",
    OrderSummary = "confirmationOrderSummary",
    OrderSummaryDeliveryLabel = "confirmationSummaryDeliveryLabel",
    OrderSummaryShippingLabel = "confirmationSummaryShipLabel",
    OrderSummaryShippingAmount = "confirmationSummaryShipAmount",
    DeliveryLabel = "confirmationDeliveryLabel",
    DeliveryProductName = "confirmationProductName",
    ProductDiscount = "confirmationDiscountPrice",
    ProductQuantity = "confirmationQty",
    DeliveryDate = "confirmationDeliveryDate",
    ProductSize = "confirmationSize",
    DiscountPercent = "confirmationDiscountPercent",
    OriginalPriceStrikeThrough = "confirmationStrikethrough",
    DeliveryProductPrice = "confirmationNormalPrice",
    TaxLabel = "confirmationTaxLabel",
    SavingsLabel = "confirmationItemsSavingsLabel",
    ItemsLabel = "confirmationItemsLabel",
    TotalLabel = "confirmationTotalLabel",
    TaxAmount = "confirmationTaxAmount",
    SavingsAmount = "confirmationItemsSavingsAmount",
    ItemsTotal = "confirmationItemsTotal",
    OrderSummaryDeliveryFee = "confirmationDeliveryFee",
    TotalAmount = "confirmationTotalAmount",
    PaymentLabel = "confirmationPaymentLabel",
    PaymentInfo = "confirmationPaymentDetail",
    DeliveryAddress = "confirmationDeliveryAddress",
    Email = "confirmationEmail",
    Phone = "confirmationPhone",
    BillingMessage = "confirmationBillingMessage",
    ContactMessage = "confirmationContactMessage",
    ReceiptEmail = "confirmationSentEmailToMessage",
    DeliveryTypeLabel = "confirmationDeliveryTypeLabel",
    ShippingProductName = "confirmationShippingLineItem",
    ShippingSection = "confirmationShippingSection",
    ShippingSectionTitle = "confirmationShippingTitle",
    ShippingNumber = "confirmationShipmentNumber",
    ShippingSectionSize = "confirmationSectionSize",
    ShippingSectionQuantity = "confirmationSectionQTY",
    ShippingSectionPrice = "confirmationShipLineItemPrice"
}

//data testid's that will be used for Progressive
export enum ProgressiveDataTestIds {
    ProgressiveApplyNow = "progressiveApplyNow",
    ProgressiveLearnMore = "progressiveLearnMore",
    ProgressivePhoneNumber = "progressivePhoneNumber",
    ProgressiveSSN = "progressiveSSN",
    ProgressiveLookUpButton = "progressiveLookUpButton",
    ProgressiveCallButton = "progressiveCallButton",
    ProgressiveApprovedPopUpContinueButton = "progressiveApprovedPopUpContinueButton",
    ProgressiveApprovedPopUpGoBackButton = "progressiveApprovedPopUpGoBackButton",
    ProgressiveApprovedPopUpCloseButton = "progressiveApprovedPopUpCloseButton",
    ProgressiveErrorLimitExceed = "progressiveErrorLimitExceed",
    ProgressiveErrorLimitExceedCTA = "progressiveErrorLimitExceedCTA",
    ProgressiveErrorCartMinimunThreshold = "progressiveErrorCartMinimunThreshold",
    ProgressiveErrorOutOfState = "progressiveErrorOutOfState",
    ProgressiveLeaseNotFound = "progressiveLeaseNotFound",
    ProgressiveAddressMismatch = "progressiveAddressMismatch",
}